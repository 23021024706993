import React, { useEffect, useMemo, useState } from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { ResponsiveContainer } from '../atoms/Container';
import { CannonBreadcrumbs } from '../atoms';
import { useTableOrMobile } from '../hooks';
import { cannonStyled, useCannonStyletron } from '../theme';
import { VideosSection } from '../components/sections';
import { IFileImage } from '../@types';

// GRAPHQL QUERY
const query = graphql`
  query {
    allStrapiVideos {
      edges {
        node {
          video_info {
            description
            category
            url
            id
            media {
              alternativeText
              ext
              hash
              url
            }
            videoPreviewImage {
              ext
              hash
              url
              formats {
                large {
                  ext
                  hash
                  url
                }
                medium {
                  ext
                  hash
                  url
                }
                small {
                  ext
                  hash
                  url
                }
                thumbnail {
                  ext
                  hash
                  url
                }
              }
            }
          }
          title
          id
        }
      }
    }
  }
`;

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    width: '90%',
    textAlign: 'left',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '10px',
    marginBottom: '40px',
  })
);
export interface IVideoInfo {
  description: string;
  category: string;
  media: IFileImage;
  videoPreviewImage: IFileImage;
  url: string;
  id: string;
}
interface IVideosElements {
  video_info: Array<IVideoInfo>;
  title: string;
  id: string;
}
interface IVideos {
  allStrapiVideos: { edges: { node: IVideosElements } };
}

// COMPONENT
const Videos: React.FC<PageProps> = (props: PageProps) => {
  // HOOKS
  const [isMobile, isTablet] = useTableOrMobile();
  const data = useStaticQuery<IVideos>(query);
  const { allStrapiVideos } = data;
  // STATE

  // HANDLERS & FUNCTIONS

  // EFFECTS & CALLBACKS

  // RENDER

  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <BreadCrumbsContainer isMobile={isMobile}>
        <CannonBreadcrumbs
          pages={[
            { title: 'Home', href: '/' },
            { title: 'Support', href: '' },
            { title: 'Videos', href: `/videos` },
          ]}
        />
      </BreadCrumbsContainer>
      <VideosSection
        videosArr={allStrapiVideos.edges[0].node.video_info}
        title={allStrapiVideos.edges[0].node.title}
      />
    </LayoutProvider>
  );
};

export default Videos;
